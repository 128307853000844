import { InputHTMLAttributes } from "react";
import twm from "~/cn";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  tight?: boolean;
}

export default function Input({ className, tight, ...props }: InputProps) {
  return (
    <input
      type="text"
      className={twm(
        "block w-full rounded-md border border-gray-300 bg-white p-2.5 text-sm text-slate-900 placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500",
        {
          "py-1.5": tight,
        },
        className,
      )}
      {...props}
    />
  );
}
